import { FeedScope } from '@/__generated__/graphql.ts';
import { LayoutCard } from '@/components/layout/card.tsx';
import { paths } from '@/routes-utils/paths.ts';
import { useLoaderData, useNavigate } from 'react-router';
import { EmptyStatePrimaryAction } from '@synoptic/ui-kit/empty-state/empty-state-primary-action.js';
import { EmptyState } from '@synoptic/ui-kit/empty-state/empty-state.js';
import { PostIcon } from '@synoptic/ui-kit/icons/react/post.js';
import { emptyHomeFeedContainer } from './empty-home-feed.css.ts';
import { setFeedType } from './feed-type.ts';
import { HomeLoaderData } from './loader.ts';

export const EmptyHomeFeed = () => {
  const { feedType } = useLoaderData() as HomeLoaderData;
  const navigate = useNavigate();

  const description =
    feedType === FeedScope.FeedStream
      ? 'This is where you will see posts from streams you follow. Follow streams to see posts here!'
      : "This is where you'll be able to see posts from people you follow. Let's start growing your network by choosing some creators to follow!";

  const action =
    feedType === FeedScope.FeedFollowing ? (
      <EmptyStatePrimaryAction
        onClick={() => {
          setFeedType(FeedScope.FeedViral);
          navigate('/', { replace: true });
        }}
      >
        Explore Viral
      </EmptyStatePrimaryAction>
    ) : feedType === FeedScope.FeedStream ? (
      <EmptyStatePrimaryAction
        onClick={() => {
          navigate(paths.streams);
        }}
      >
        Explore data streams
      </EmptyStatePrimaryAction>
    ) : null;

  const Card = feedType === FeedScope.FeedStream ? 'div' : LayoutCard;

  return (
    <Card className={emptyHomeFeedContainer}>
      <EmptyState
        illustration={<PostIcon />}
        heading="No content yet"
        description={description}
        action={action}
      />
    </Card>
  );
};
