import {
  FeedScope,
  PostCardFragmentFragment,
  RepostCardFragmentFragment,
  StreamPostCardFragmentFragment,
  TournamentPostCardFragmentFragment,
} from '@/__generated__/graphql.ts';
import { Delayed } from '@/components/delayed.tsx';
import { onRestoreFeed } from '@/components/feed/feed.tsx';
import {
  HighlightedFeedWrapper,
  List,
} from '@/components/highlighted-feed-wrapper/highlighted-feed-wrapper.tsx';
import { PostFeedSkeleton } from '@/components/post-feed/post-feed-skeleton.tsx';
import { PostFeed } from '@/components/post-feed/post-feed.tsx';
import { SubscribedPostFeed } from '@/components/post-feed/subscribed-post-feed.tsx';
import {
  NetworkStatus,
  useQueryRefHandlers,
  useReadQuery,
} from '@apollo/client';
import { Location, useLoaderData } from 'react-router';
import { startTransition, Suspense } from 'react';
import { EmptyHomeFeed } from './empty-home-feed.tsx';
import { FollowingSubscription } from './following-subscription.tsx';
import { HomeLoaderData } from './loader.ts';

const HomeFeedComponent = () => {
  const { queryRef, feedType } = useLoaderData() as HomeLoaderData;

  const { fetchMore, refetch } = useQueryRefHandlers(queryRef);

  const {
    data: { feed },
    networkStatus,
  } = useReadQuery(queryRef);

  const onRestore: onRestoreFeed = ({ prepend, fetchOptions, setOutdated }) => {
    startTransition(() => {
      fetchMore({
        variables: {
          nextToken: null,
          limit: 20,
        },
        context: { fetchOptions },
        updateQuery(previousQueryResult, { fetchMoreResult }) {
          if (previousQueryResult.feed.items.length === 0) {
            return fetchMoreResult;
          }
          const prevFirstItemId = previousQueryResult.feed.items[0].id;
          const idx = fetchMoreResult.feed.items.findIndex(
            (fresh) => fresh.id === prevFirstItemId,
          );

          if (idx > -1) {
            const newItems = fetchMoreResult.feed.items.slice(0, idx);

            prepend(newItems.length);

            return {
              ...previousQueryResult,

              feed: {
                ...previousQueryResult.feed,

                items: newItems.concat(previousQueryResult.feed.items),
                nextToken: previousQueryResult.feed.nextToken,
              },
            };
          } else {
            setOutdated(true);

            return previousQueryResult;
          }
        },
      }).catch(() => {});
    });
  };

  const onEndReached = () => {
    if (feed.nextToken) {
      startTransition(() => {
        fetchMore({ variables: { nextToken: feed.nextToken } });
      });
    }
  };

  const getStorageKey = (loc: Location) => loc.pathname + feedType;

  return feedType === FeedScope.FeedStream ? (
    <HighlightedFeedWrapper
      label="Followed data streams"
      empty={{ state: <EmptyHomeFeed />, isEmpty: feed.items.length <= 0 }}
    >
      <SubscribedPostFeed
        onRestore={onRestore}
        onReset={refetch}
        getStorageKey={getStorageKey}
        loading={networkStatus === NetworkStatus.loading}
        data={feed.items as StreamPostCardFragmentFragment[]}
        endReached={onEndReached}
        components={{
          List,
        }}
        gap={0}
        subscription={(onData) => (
          <FollowingSubscription
            onData={onData}
            feedScope={FeedScope.FeedStream}
            refetchQuery={refetch}
          />
        )}
      />
    </HighlightedFeedWrapper>
  ) : feedType === FeedScope.FeedFollowing ? (
    <SubscribedPostFeed
      onRestore={onRestore}
      onReset={refetch}
      getStorageKey={getStorageKey}
      loading={networkStatus === NetworkStatus.loading}
      data={
        feed.items as
          | PostCardFragmentFragment[]
          | RepostCardFragmentFragment[]
          | TournamentPostCardFragmentFragment[]
      }
      endReached={onEndReached}
      components={{
        EmptyPlaceholder: EmptyHomeFeed,
      }}
      subscription={(onData) => (
        <FollowingSubscription
          onData={onData}
          feedScope={FeedScope.FeedFollowing}
          refetchQuery={refetch}
        />
      )}
    />
  ) : (
    <PostFeed
      onRestore={onRestore}
      onReset={refetch}
      getStorageKey={getStorageKey}
      style={{
        opacity: networkStatus === NetworkStatus.loading ? 0.5 : '',
      }}
      data={
        feed.items as
          | PostCardFragmentFragment[]
          | RepostCardFragmentFragment[]
          | TournamentPostCardFragmentFragment[]
      }
      endReached={onEndReached}
      components={{
        EmptyPlaceholder: EmptyHomeFeed,
      }}
    />
  );
};

export const HomeFeed = () => {
  const { feedType } = useLoaderData() as HomeLoaderData;

  return (
    <Suspense
      key={feedType}
      fallback={
        <Delayed delayMs={2000}>
          <PostFeedSkeleton />
        </Delayed>
      }
    >
      <HomeFeedComponent />
    </Suspense>
  );
};
