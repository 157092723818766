import { FeedScope } from '@/__generated__/graphql.ts';
import { ErrorPage } from '@/components/error-page/error-page.tsx';
import { Page, PageContent } from '@/components/layout/page.tsx';
import { PageHeaderSearch } from '@/components/page-header/page-header-search.tsx';
import { PageHeader } from '@/components/page-header/page-header.tsx';
import { Link } from '@/routes-utils/navigation.tsx';
import {
  protectLoader,
  protectServerLoader,
} from '@/routes-utils/protected-loader.ts';
import { BrightStarIcon } from '@synoptic/ui-kit/icons/react/bright-star.tsx';
import { InsightsIcon } from '@synoptic/ui-kit/icons/react/insights.js';
import { useIsTablet } from '@synoptic/ui-kit/responsive/hooks.js';
import { TabNavLink } from '@synoptic/ui-kit/tab-nav/tab-nav-link.tsx';
import { TabNav } from '@synoptic/ui-kit/tab-nav/tab-nav.js';
import { useLoaderData } from 'react-router';
import { setFeedType } from './feed-type.ts';
import { HomeFeed } from './home-feed.tsx';
import { navContainer } from './home.css.ts';
import { homeLoader, HomeLoaderData } from './loader.ts';

export const loader = protectServerLoader();
export const clientLoader = protectLoader(homeLoader);

export const ErrorBoundary = ErrorPage;

export default function HomePage() {
  const { feedType } = useLoaderData() as HomeLoaderData;

  const isTablet = useIsTablet();

  return (
    <Page>
      <PageHeader>
        <TabNav
          size={isTablet ? 'large' : 'small'}
          className={navContainer}
          noBorder
        >
          <TabNavLink active={feedType === FeedScope.FeedFollowing} asChild>
            <Link onClick={() => setFeedType(FeedScope.FeedFollowing)} to="/">
              Following
            </Link>
          </TabNavLink>
          <TabNavLink active={feedType === FeedScope.FeedViral} asChild>
            <Link onClick={() => setFeedType(FeedScope.FeedViral)} to="/">
              <InsightsIcon />
              Viral
            </Link>
          </TabNavLink>
          <TabNavLink active={feedType === FeedScope.FeedStream} asChild>
            <Link onClick={() => setFeedType(FeedScope.FeedStream)} to="/">
              <BrightStarIcon />
              Data streams
            </Link>
          </TabNavLink>
        </TabNav>
        <PageHeaderSearch />
      </PageHeader>
      <PageContent>
        <HomeFeed />
      </PageContent>
    </Page>
  );
}
